import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/9fHV54TpNeo">
    <SEO title="Refugees, Immigrants, and the Southern Border - Radical Lectures" />
  </Layout>
)

export default SermonPost
